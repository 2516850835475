const colegios = [
    {nombre:"Academia Argüello | Córdoba | Córdoba"},
    {nombre:"Asociación educativa Agrotécnica y orientada Pío León | Colonia Caroya | Córdoba"},
    {nombre:"Bachillerato Humanista Moderno de Salta | Salta | Salta"},
    {nombre:"Centro Educacional San Jorge | Córdoba | Córdoba "},
    {nombre:"Centro Educativo Franciscano San Buenaventura | Córdoba | Córdoba"},
    {nombre:"Centro Educativo Nuevo Siglo | Córdoba | Córdoba"},
    {nombre:"Centro Provincial de Enseñanza Media No 6 | Cutral Co | Neuquén"},
    {nombre:"Colegio 25 de mayo | Córdoba | Córdoba"},
    {nombre:"Colegio Alemán | Córdoba | Córdoba"},
    {nombre:"Colegio Alemán de Villa General Belgrano | Villa General Belgrano | Córdoba"},
    {nombre:"Colegio Anglo Americano | Alta Gracia | Córdoba"},
    {nombre:"Colegio Belgrano - Canónigos Regulares de Letrán | Salta | Salta"},
    {nombre:"Colegio Big Ben School | Santiago del Estero | Santiago del Estero"},
    {nombre:"Colegio Bilingüe Abraham Lincoln | Comodoro Rivadavia | Chubut"},
    {nombre:"Colegio Bilingüe Bicultural Italo Argentino Dante Alighieri | Río Cuarto | Córdoba"},
    {nombre:"Colegio Castelfranco Bilingüe Bicultural | Córdoba | Córdoba"},
    {nombre:"Colegio Cinco Ríos | Cordoba | Córdoba"},
    {nombre:"Colegio Confluencia | Neuquén | Neuquén"},
    {nombre:"Colegio de María | Córdoba | Córdoba"},
    {nombre:"Colegio del Salvador | San Salvador de Jujuy | Jujuy"},
    {nombre:"Colegio Diocesano Monseñor Alemán | Ushuaia"},
    {nombre:"Colegio Domingo Savio | General Roca | Rio Negro"},
    {nombre:"Colegio Don Bosco | Neuquén | Neuquén"},
    {nombre:"Colegio Don Bosco | Río Grande | Tierra del Fuego "},
    {nombre:"Colegio El Torreón - Secundario | Córdoba | Córdoba"},
    {nombre:"Colegio Evangélico Alfredo Furniss | Santiago del Estero | Santiago del Estero"},
    {nombre:"Colegio FASTA Inmaculada Concepción | San Francisco | Córdoba"},
    {nombre:"Colegio FASTA Jesús de la Misericordia | San Francisco | Córdoba"},
    {nombre:"Colegio FASTA Miguel Ángel Tobares | San Martín de los Andes | Neuquén"},
    {nombre:"Colegio Fasta Villa Eucarística | Córdoba | Córdoba"},
    {nombre:"Colegio Gabriel Taborín | Córdoba | Córdoba"},
    {nombre:"Colegio Hermano Hermas de Bruijn | Santiago del Estero | Santiago del Estero"},
    {nombre:"Colegio Jesús María de la Calera | La Calera | Córdoba"},
    {nombre:"Colegio La Salle | Córdoba | Córdoba"},
    {nombre:"Colegio Los Lapachos | San Salvador de Jujuy | Jujuy"},
    {nombre:"Colegio Luterano Concordia | Córdoba | Córdoba"},
    {nombre:"Colegio Maestro Diehl | Córdoba | Córdoba"},
    {nombre:"Colegio Mater Purissima | Córdoba | Córdoba"},
    {nombre:"Colegio Nacional del Monserrat de la Universidad Nacional de Córdoba | Córdoba | Córdoba"},
    {nombre:"Colegio Nuestra Señora del Huerto | San Salvador de Jujuy | Jujuy"},
    {nombre:"Colegio Nueva Siembra | San Salvador de Jujuy | Jujuy"},
    {nombre:"Colegio Padre Claret | Córdoba | Córdoba"},
    {nombre:"Colegio Plaza Mayor | Paraná | Entre Ríos"},
    {nombre:"Colegio Privado de La Ribera | Formosa | Formosa"},
    {nombre:"Colegio Privado Gral Manuel Belgrano | San Fernando del Valle de Catamarca | Catamarca"},
    {nombre:"Colegio Privado Inmaculada Concepción | Recreo | Catamarca"},
    {nombre:"Colegio Privado Pía Didoménico | San Fernando del Valle de Catamarca | Catamarca"},
    {nombre:"Colegio San José | Córdoba | Córdoba"},
    {nombre:"Colegio San Pablo | Pergamino | Buenos Aires"},
    {nombre:"Colegio San Pablo N° 8110 | Salta | Salta"},
    {nombre:"Colegio San Patricio | Córdoba | Córdoba"},
    {nombre:"Colegio San Pedro Apóstol | Córdoba | Córdoba"},
    {nombre:"Colegio Santa María de Salta | Salta | Salta"},
    {nombre:"Colegio Santa Bárbara | San Salvador de Jujuy | Jujuy"},
    {nombre:"Colegio Santa Eufrasia | Río Cuarto | Córdoba"},
    {nombre:"Colegio Santo Tomás | Cordoba | Córdoba"},
    {nombre:"Colegio Secundario Bilingüe Mark Twain | Córdoba | Córdoba"},
    {nombre:"Complejo Educativo José Hernández | San Salvador de Jujuy | Jujuy"},
    {nombre:"Escuela Bilingûe Bicultural Dante Alighieri | San Francisco | Córdoba"},
    {nombre:"Escuela de Educación Secundaria N°6 María Marta Brignone | Banderaló | Buenos Aires"},
    {nombre:"Escuela de Educación Secundaria N°7 | General Villegas | Buenos Aires"},
    {nombre:"Escuela de Educación Secundaria Orientada N°268 Miguel de Güemes | Villa Trinidad | Santa Fe"},
    {nombre:"Escuela de la Familia Agrícola | Colonia Caroya | Córdoba"},
    {nombre:"Escuela Dr. Dalmacio Vélez Sarsfield | Arroyito | Córdoba"},
    {nombre:"Escuela Kumelen | Córdoba | Córdoba"},
    {nombre:"Escuela Normal Superior Dalmacio Vélez Sarsfield | Las Varillas | Córdoba"},
    {nombre:"Escuela Normal Superior General Manuel Belgrano | Marcos Juárez | Córdoba"},
    {nombre:"Escuela Normal Superior Joaquín V. González | Chilecito | La Rioja"},
    {nombre:"Escuela Normal Superior República del Perú | Cruz del Eje | Córdoba"},
    {nombre:"Escuela Parroquial San Luis Gonzaga | Río Segundo | Córdoba"},
    {nombre:"Escuela Privada Dante Alighieri | La Rioja | La Rioja"},
    {nombre:"Escuela Privada Gabriela Mistral | La Rioja | La Rioja"},
    {nombre:"Escuela Superior de Comercio y Bachillerato Anexo | Leones | Córdoba"},
    {nombre:"Escuela Superior Integral de Lechería | Villa María | Córdoba"},
    {nombre:"Establecimiento Educativo Provincial Técnico No 704 “Prefectura Naval Argentina”"},
    {nombre:"Fundación Educativa y Cultural San Esteban | San Carlos de Bariloche | Río Negro"},
    {nombre:"Institución Escuti | Córdoba | Córdoba"},
    {nombre:"Instituto Amparo de María | Córdoba | Córdoba"},
    {nombre:"Instituto Bachillerato Agrotécnico Ibat San José | Villa del Rosario | Córdoba"},
    {nombre:"Instituto Berrotarán | Berrotarán | Córdoba"},
    {nombre:"Instituto Bilingüe Sagrada Familia | Vicuña Mackena | Córdoba"},
    {nombre:"Instituto Bilingüe San Ignacio de Loyola | Río Cuarto | Córdoba"},
    {nombre:"Instituto Carlos Saavedra Lamas | Río Tercero | Córdoba"},
    {nombre:"Instituto Catalina Caviglia De Visca | Oliva | Córdoba"},
    {nombre:"Instituto Cerro Azul | Villa Dolores | Córdoba"},
    {nombre:"Instituto Contardo Ferrini | Río Primero | Córdoba"},
    {nombre:"Instituto Dante Alighieri | Córdoba | Córdoba"},
    {nombre:"Instituto Divino Corazón | Hernando | Córdoba"},
    {nombre:"Instituto Doctor Antonio Nores | Córdoba | Córdoba"},
    {nombre:"Instituto Don Orione | Córdoba | Córdoba"},
    {nombre:"Instituto de Enseñanza Domingo Faustino Sarmiento | Córdoba | Córdoba"},
    {nombre:"Instituto de Enseñanza José Manuel Estrada | Altos de Chipión | Córdoba"},
    {nombre:"Instituto de Enseñanza Privado El Obraje | Alta Gracia | Córdoba"},
    {nombre:"Instituto de Enseñanza Privado Miguel Cané | Tránsito | Córdoba"},
    {nombre:"Instituto de Enseñanza Secundaria | Villa Carlos Paz | Córdoba"},
    {nombre:"Instituto de Nivel Medio La Francia | La Francia | Córdoba"},
    {nombre:"Instituto de San José - Hermanas Dominicas | Córdoba | Córdoba"},
    {nombre:"Instituto del Carmen | Córdoba | Córdoba"},
    {nombre:"Instituto del Espíritu Santo | Río Ceballos | Córdoba"},
    {nombre:"Instituto del Inmaculado Corazón de María Adoratrices | Córdoba | Córdoba"},
    {nombre:"Instituto del Rosario de Villa María | Villa María | Córdoba"},
    {nombre:"Instituto Educativo Nuevo Milenio | Unquillo | Córdoba"},
    {nombre:"Instituto Escuelas Pías | Córdoba | Córdoba"},
    {nombre:"Instituto Francisco Luis Bernárdez | Córdoba | Córdoba"},
    {nombre:"Instituto General José María Paz | Monte Buey | Córdoba"},
    {nombre:"Instituto General Manuel Belgrano | Pozo del Molle | Córdoba"},
    {nombre:"Instituto General San Martín Cooperativa Ltda. de Enseñanza | Villa Allende | Córdoba"},
    {nombre:"Instituto Gustavo Martínez Zuviría | Las Varillas | Córdoba"},
    {nombre:"Instituto Humanista de la Santísima Trinidad | San Lorenzo | Salta"},
    {nombre:"Instituto Jesuita Sagrada Familia | Córdoba | Córdoba"},
    {nombre:"Instituto Jesús María | Córdoba | Córdoba"},
    {nombre:"Instituto Jesús, María y José | Río Tercero | Córdoba"},
    {nombre:"Instituto Jorge Vocos Lescano | Río Segundo | Córdoba"},
    {nombre:"Instituto José de San Martín | San Marcos Sud | Córdoba"},
    {nombre:"Instituto José Peña | Córdoba | Córdoba"},
    {nombre:"Instituto Jóvenes Argentinos | Córdoba | Córdoba "},
    {nombre:"Instituto Juan Bautista Alberdi | Viamonte | Córdoba"},
    {nombre:"Instituto Juana Micono de Giardino | Villa Giardino | Córdoba"},
    {nombre:"Instituto La Santísima Trinidad | Villa María | Córdoba"},
    {nombre:"Instituto León XIII | Córdoba | Córdoba"},
    {nombre:"Instituto Leonardo Da Vinci | Río Cuarto | Córdoba"},   
    {nombre:"Instituto los Sagrados Corazones | Villa Huidobro | Córdoba"},
    {nombre:"Instituto Madre de la Misericordia | Posadas | Misiones"},
    {nombre:"Instituto Madres Escolapias | Córdoba | Córdoba"},
    {nombre:"Instituto Marcelo Spínola | Caleta Olivia | Santa Cruz"},
    {nombre:"Instituto María Auxiliadora | Neuquén | Neuquén"},
    {nombre:"Instituto María Auxiliadora | Río Gallegos | Santa Cruz"},
    {nombre:"Instituto María Auxiliadora | Santa Rosa | La Pampa"},
    {nombre:"Instituto María de Nazareth | Córdoba | Córdoba"},
    {nombre:"Instituto Milenio Villa Allende | Villa Allende | Córdoba"},
    {nombre:"Instituto Nuestra Madre de la Merced | Córdoba | Córdoba"},
    {nombre:"Instituto Nuestra Señora | Córdoba | Córdoba"},
    {nombre:"Instituto Nuestra Señora del Calvario | Valle Hermoso | Córdoba"},
    {nombre:"Instituto Nuestra Señora del Huerto | Córdoba | Córdoba"},
    {nombre:"Instituto Nuestra Señora del Huerto | Jesús María | Córdoba"},
    {nombre:"Instituto Nuestra Señora del Huerto | Orán | Salta"},
    {nombre:"Instituto Nuestra Señora de Fátima | Cipolletti | Río Negro"},
    {nombre:"Instituto Nuestra Señora de la Merced | Arroyito | Córdoba"},
    {nombre:"Instituto Nuestra Señora de la Merced | Córdoba | Córdoba"},
    {nombre:"Instituto Nuestra Señora de la Merced | La Paz | Entre Ríos"},
    {nombre:"Instituto Nuestra Señora de la Misericordia | Alta Gracia | Córdoba"},
    {nombre:"Instituto Nuestra Señora de la Misericordia | Canals | Córdoba"},
    {nombre:"Instituto Nuestra Señora de Lourdes | La Cumbre | Córdoba"},
    {nombre:"Instituto Nuestra Señora de Luján | Intendente Alvear | La Pampa"},
    {nombre:"Instituto Nuestra Señora de Nieva | Córdoba | Córdoba"},
    {nombre:"Instituto Nuestra Señora del Pilar INSP N°13 | Pilar | Córdoba"},
    {nombre:"Instituto Nuestra Señora del Rosario del Milagro | Jesús María | Córdoba"},
    {nombre:"Instituto Nuestra Señora del Sagrado Corazón | Córdoba | Córdoba"},
    {nombre:"Instituto Nuestra Señora del Valle | Córdoba | Córdoba"},
    {nombre:"Instituto Nuevo Siglo FEDINUS | General Roca. Río Negro"},
    {nombre:"Instituto Pablo Pizzurno | Hernando | Córdoba"},
    {nombre:"Instituto Parroquial Bernardo D Elía | Villa Carlos Paz | Córdoba"},
    {nombre:"Instituto Parroquial Cristo Redentor | Córdoba | Córdoba"},
    {nombre:"Instituto Parroquial Nuestra Señora del Carmen | Córdoba | Córdoba"},
    {nombre:"Instituto Parroquial Monte Cristo | Monte Cristo | Córdoba"},
    {nombre:"Instituto Parroquial Pío XII | Despeñaderos | Córdoba"},
    {nombre:"Instituto Parroquial Remedios Escalada de San Martín | Villa Carlos Paz | Córdoba"},
    {nombre:"Instituto Parroquial Sagrada Familia | Realicó | La Pampa"},
    {nombre:"Instituto Parroquial San José | Córdoba | Córdoba"},
    {nombre:"Instituto Primo Capraro | San Carlos de Bariloche | Río Negro"},
    {nombre:"Instituto Privado Aleluya | San Luis | San Luis"},
    {nombre:"Instituto Privado Cristo Rey | Río Cuarto | Córdoba"},
    {nombre:"Instituto Privado Diocesano Dr. Alexis Carrel | Río Tercero | Córdoba"},
    {nombre:"Instituto Privado José María Paz.  Devoto, Córdoba"},
    {nombre:"Instituto Privado Manuel Belgrano | La Rioja | La Rioja"},
    {nombre:"Instituto Privado Sagrado Corazón | Deán Funes | Córdoba "},
    {nombre:"Instituto Provincial de Educación Media San Carlos N°221 | Jovita | Córdoba"},
    {nombre:"Instituto Sagrado Corazón | San Francisco | Córdoba"},
    {nombre:"Instituto San Antonio | Villa María | Córdoba"},
    {nombre:"Instituto San Alberto y San Enrique | Serrano | Córdoba"},
    {nombre:"Instituto San Buenaventura | Río Cuarto | Córdoba"},
    {nombre:"Instituto San Francisco de Asís | San Francisco | Córdoba"}, 
    {nombre:"Instituto San José | Bell Ville | Córdoba"},
    {nombre:"Instituto San Pablo | Cruz Grande - La Cumbre | Córdoba"},
    {nombre:"Instituto San Pedro Nolasco | Santiago del Estero | Santiago del Estero"},
    {nombre:"Instituto Santa Ana | Córdoba | Córdoba"},
    {nombre:"Instituto Santa Catalina Virgen y Mártir | Ayen | Río Negro"},
    {nombre:"Instituto Santa Teresa de Jesús | Córdoba | Córdoba"},
    {nombre:"Instituto Santísima Trinidad | Córdoba | Córdoba"},
    {nombre:"Instituto Santísima Trinidad | Hernando | Córdoba"},
    {nombre:"Instituto Secundario Bernardino Rivadavia | Villa María | Córdoba"},
    {nombre:"Instituto Secundario Carlos Pellegrini | Pilar | Córdoba "},
    {nombre:"Instituto Secundario de la Inmaculada | Córdoba | Capital"},
    {nombre:"Instituto Secundario Dr. Raúl Loza | Luque | Córdoba"},
    {nombre:"Instituto Secundario General Levalle | General Levalle | Córdoba"},
    {nombre:"Instituto Secundario General Paz | Etruria | Córdoba"},
    {nombre:"Instituto Secundario General San Martín - Centro Unión Israelita de Córdoba | Cordoba | Córdoba"},
    {nombre:"Instituto Secundario La Merced | Río Cuarto | Córdoba"},
    {nombre:"Instituto Secundario Libertador General San Martín | Ucacha | Córdoba"},
    {nombre:"Instituto Secundario Pablo Pizzurno | Sampacho | Córdoba"},
    {nombre:"Instituto Secundario Privado Juan XXIII | Córdoba | Córdoba"},
    {nombre:"Instituto Secundario Privado Olegario Víctor Andrade | Villa Retiro | Córdoba"},
    {nombre:"Instituto Superior Enrique Guillermo Hood | San Fernando del Valle | Catamarca"},
    {nombre:"Instituto Técnico Adrián de Urquía | General Deheza | Córdoba (ID Técnicos)"},
    {nombre:"Instituto Técnico Renault | Córdoba | Córdoba (ID y ID Técnicos)"},
    {nombre:"Instituto Técnico Salesiano Villada | Córdoba | Córdoba (ID Técnicos)"},
    {nombre:"Instituto Tecnológico Isaac Newton | Córdoba | Córdoba"},
    {nombre:"Ipem N°178 América Latina | Mattaldi | Córdoba"},
    {nombre:"IPET N 263 Dr. Bernardo Houssay | Las Varillas | Cordoba"},
    {nombre:"IPET N°266 Gral Savio | Río Tercero | Córdoba (ID Técnicos)"},
    {nombre:"IPET N°55 Villa del Rosario | Córdoba | Córdoba (ID Técnicos)"},
    {nombre:"Liceo Militar General Paz | Córdoba | Córdoba"},
    {nombre:"Padre Ramón de la Quintana | San Fernando del Valle | Catamarca"},
    {nombre:"Santiago del Estero English High School | Santiago del Estero | Santiago del Estero"},
    {nombre:"Unidad Educativa Maryland | Villa Allende | Córdoba"},
    {nombre:"Uzzi College | Salta | Salta"}
    ]
    
export default colegios